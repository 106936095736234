<template>
  <div>
    <v-select
      v-if="renderSelect"
      id="vSelectJobPosition"
      ref="vSelectJobPosiiton"
      v-model="selected_option"
      :multiple="multiple"
      :disabled="disabled"
      :placeholder="placeholder"
      :options="options"
      :class="validator && selected_option && selected_option[label] ? '': 'danger-select' "
      :label="label"
      @search="initSearch"
      @input="clearIfEmpty"
    >
      <template v-slot:option="option">
        <div>
          <b v-if="option.source_name === 'new'">
            {{
              $length(options) == 1
                ? 'No result, select this to add: '
                : 'Add New: '
            }}
          </b>
          {{ option.text }}
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import VSelect from 'vue-select'

export default {
  components: {
    VSelect,
  },
  props: {
    placeholder: { type: String, default: 'Select the company/companies you belong to' },
    stateErrors: { type: Array, default: null },
    multiple: { type: Boolean, default: false },
    allowAddNew: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    validator: { type: Boolean, default: false },
    clearSelect: { type: Boolean, default: false },
    targetDb: { type: String, default: 'hq_dashboard' },
    eventId: { type: String, default: null },
    label: { type: String, default: 'text' },
    sourceNameList: { type: Array, default: () => [] },
    companyId: { type: String, default: null },
  },
  data() {
    return {
      isFirstLoad: true,
      options: [],
      selected_option: null,
      renderSelect: true,
      isUpdating: false,
    }
  },
  watch: {
    selected_option(val) {
      if (this.$isNotEmpty(val)) {
        const selected = { ...val }
        if (!this.isFirstLoad) {
          if (!this.isUpdating) {
            if (selected.source_name === 'new') {
              selected.text = selected.value
              this.selected_option = selected
              this.isUpdating = true
              this.$emit('selected', selected, true)
            } else {
              this.$emit('selected', selected)
            }
          } else {
            this.isUpdating = false
          }
        }

        // if (!this.isFirstLoad) {
        //   if (!val.is_from_db && this.$isNotEmpty(val.id)) {
        //     this.createCompany(null, val)
        //   }
        //   this.$emit('company-selected', val)
        // }
      }
    },
    clearSelect(val) {
      if (val) {
        this.selected_option = this.multiple ? [] : {}
      }
    },
  },
  created() {
    this.debouncedSearchForPosition = debounce((loading, search, context) => {
      if (this.$isNotEmpty(search)) {
        loading(true)
        this.getResult(search, loading)
      }
    }, 1000)
  },
  mounted() {
    // this.selected_companies = this.multiple ? [] : {}
    // const inputElement = this.$refs.vSelectJobPosiiton.$refs.search
    // inputElement.addEventListener('click', this.focusSearch);
  },
  methods: {
    initSearch(search, loading) {
      this.debouncedSearchForPosition(loading, search, this)
    },
    clearIfEmpty(search) {
      if (this.$isEmpty(search)) {
        this.$emit('selected', {}, true)
      }
    },
    focusSearch() {
      const inputElement = this.$refs.vSelectJobPosiiton.$refs.search
      inputElement.value = this.selected_option.text
      this.isUpdating = true
      inputElement.dispatchEvent(new Event('input'))
    },

    // getSearchResult: debounce((loading, search, vm) => {
    //   vm.getResult(search, loading)
    // }, 1000),

    getResult(query, loading = null) {
      this.$set(this, 'options', [])
      const params = {}

      if (this.$isNotEmpty(this.sourceNameList)) {
        params.source_name_list = this.sourceNameList
      }
      if (['applicant-tracking-system-edit'].includes(this.$route.name)) {
        params.source_name_list = this.sourceNameList ? this.sourceNameList.push('bot') : ['bot']
      }

      if (this.$isNotEmpty(this.eventId)) {
        params.event_id_is_not = this.eventId
      }

      // params.company_id = [this.$store.state.auth.ActiveUser.company_id]
      params.company_id = []
      if (this.$isNotEmpty(this.companyId)) {
        params.company_id.push(this.companyId)
      }

      this.$http
        // .post(`/api/job-position-search/${query}?params=${JSON.stringify(params)}`)
        .post(`/api/job-position-search/${query}`, { form_data: { params } })
        .then(response => {
          const { success, output, message } = response.data
          if (success) {
            this.$set(this, 'options', [])
            const option = {
              text: query,
              value: query,
              source_name: 'new',
              source_table: null,
              event_ids: [],
            }
            this.options.push(option)
            output.map(item => {
              const option = {
                text: item.position,
                value: item.id,
                source_name: item.source_name,
                source_table: item.source_table,
                source_db: item.source_db,
                event_ids: item.event_ids,
              }
              this.options.push(option)
            })
          }
          if (this.$isEmpty(this.options)) {
            if (this.allowAddNew) {
              const option = {
                // text: `No result, click to add new position: ${query}`,
                text: query,
                value: query,
                source_name: 'new',
                source_table: null,
                source_db: null,
                event_ids: [],
              }
              this.$set(this, 'options', [option])

              // this.$set(this, 'renderSelect', false)
              // setTimeout(() => {
              //   // this.$set(this, 'renderSelect', true)
              //   setTimeout(() => {
              //     const inputElement = this.$refs.vSelectJobPosiiton.$refs.search
              //     // const selectOption = {...option, text: query}
              //     // this.$refs.vSelectJobPosiiton.value = selectOption
              //     inputElement.focus()
              //   }, 5)
              // }, 1)
            }
          }
          if (loading) {
            this.isFirstLoad = false
            loading(false)
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },
  },
}
</script>
