<template>
  <div>
    <v-select
      v-model="selected_events"
      autocomplete
      :multiple="multiple"
      :placeholder="placeholder"
      :disabled="disabled"
      :options="all_events"
      :class="selected_events.id ? '': 'danger-select' "
      :state="stateErrors && stateErrors.length > 0 ? false : null"
      label="name"
      @search="eventSearch"
    />
    <b-form-invalid-feedback
      tooltip
    >
      Please provide a valid input.
    </b-form-invalid-feedback>

  </div>
</template>

<script>

import { debounce } from 'lodash'
import VSelect from 'vue-select'

export default {
  components: {
    VSelect,
  },
  props: {
    placeholder: { type: String, default: 'Choose the event group' },
    stateErrors: { type: Array, default: null },
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      all_events: [],
      selected_events: [],
    }
  },
  watch: {
    selected_events(val) {
      this.$emit('event-selected', val)
    },
  },

  methods: {
    eventSearch(search, loading) {
      if (search.length > 0) {
        loading(true)
        this.searchForEvent(loading, search, this)
      }
    },
    searchForEvent: debounce((loading, search, vm) => {
      vm.getEvent(search, loading)
    }, 1000),

    getEvent(query, loading = null) {
      this.all_events = []
      this.$http
        .get(`/api/event-search/${query}`)
        .then(response => {
          this.all_events = []
          const record = response.data

          if (record.success) {
            record.data.map(data => {
              const item = { name: data.name, id: data.id }
              this.all_events.push(item)
            })
          }
          if (loading) {
            loading(false)
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },
  },
}
</script>
